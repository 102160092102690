/* ======= Modal ======= */
.modal-header {
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 45px;
    position: relative; 
    justify-content: center!important;
}

.modal button.close {
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: none;
    background: none;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index:10;
    @include opacity(0.7);
    width: 30px;
    height: 30px;
    @include border-radius(50%);
    background: $theme-color-primary;
    color: #fff;
    outline: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 0;
    margin: 0;
    &:hover {
        background: lighten($theme-color-primary, 5%);
        color: #fff !important;
        cursor: pointer;
    }
}

.modal-backdrop.in {
        @include opacity(0.8);
}

.modal-video {
    .modal-content {
        background: none;
        border: none;
        @include box-shadow(none);
    }
    .modal-header {
        padding-top: 30px;
    }
}

.modal-member {
	.member-profile img {
		max-width: 120px;
	}
	.member-name {
		font-weight: bold;
		font-size: 1.5rem;
		color: $theme-text-color-primary;
	}
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
	.modal-dialog {
        max-width: 800px;
    }
    
}
