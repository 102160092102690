.search-box {
	position: relative;
	max-width: 600px;
	.search-btn {
		background: none;
	    border: none;
	    position: absolute;
	    right: 0;
	    top: 0;
	    width: 44px;
	    height: 44px;
	    margin-right: 0;
	    color: $theme-text-color-secondary;
	    &:hover {
		    color: $theme-color-primary;
	    }
	}
}
.guide-section {
	.item {
		height: 100%;
		color: $theme-text-color-secondary;
		text-decoration: none;
		display: block;
		@include transition (all 0.4s ease-in-out);
		&:hover {
			background: white;
			@include box-shadow(0 0.5rem 1rem rgba(0,0,0,0.15));
		}
	}
	.icon-holder {
		font-size: 2rem;
		text-align: center;
		color: #fff;
		color: $theme-color-primary;
		
	}
	

}

.faq-accordion {
	
	.card {
		background: white;
	}
	.card-header {
		background: white;
	}
	.card-title {
		font-size: 1.125rem;
		position: relative;
		padding-right: 2rem;
		font-weight: normal;
		a {
			display: block;
			color: $theme-text-color-primary;
			font-weight: 500;
			&:hover {
				text-decoration: none;
				color: $theme-text-color-primary;
			}
			.svg-inline--fa {
				position: absolute;
				right: 0;
				top: 3px;
				color: $theme-text-color-secondary;
				font-weight: normal;
			}
			&[aria-expanded="true"] {
				
				.svg-inline--fa {
					-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
					transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
				}
				
			}
		}
		
		
	}
	.card-body {
		padding: 1.5rem;
		padding-top: 0;
	}
}


