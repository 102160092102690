.contact-card-box {
	background: white;
	line-height: 1.3;
	.icon-holder {
		font-size: 1.25rem;
		width: 40px;
		height: 40px;
		padding-top: 10px;
		@include gradient-directional($theme-color-primary, $theme-color-secondary, 45deg);
		@include border-radius(50%);
		text-align: center;
		color: #fff;
		@include box-shadow(0 4px 15px -6px rgba(0,0,0,0.15));
	}
	
	.contact-type {
		font-size: 1.25rem;
		color: $theme-text-color-light;
	}
    .contact-email {
	    font-weight: bold;
	    color: $theme-text-color-secondary;
	    font-size: 0.875rem;
    }

}

.contact-form-box {
	background: white;
}

.gmap-box-inner {
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;
}

